import React from 'react'
import { graphql } from 'gatsby'
import {
  Layout,
  BannerWithCards,
  Ticker,
  HeroWithTriagePathway
} from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '../pageBuilder/wrapper'

const IndexPage = ({ data }) => {
  const { seoMetaTags, moduleArea = [], ticker } = data.home

  return (
    <Layout pageType="index">
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {ticker && <Ticker items={ticker} />}

      {/* Hide this for now, likely to be permanently removed */}

      {/* <BannerWithCards
        image={image}
        heading={heading}
        caption={caption}
        cards={cards}
      /> */}

      <HeroWithTriagePathway />

      {moduleArea.length > 0 && <BlockWrapper blocks={moduleArea} />}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHome {
      id
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ticker {
        text
        link {
          ...Link
        }
      }

      moduleArea {
        ... on DatoCmsPageCardGrid {
          ...PageCardGrid
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsPathwayCardGrid {
          ...PathwayCardGrid
        }
        ... on DatoCmsFeatureGrid {
          ...FeatureGrid
        }
        ... on DatoCmsBookingButton {
          ...BookingButton
        }
        ... on DatoCmsListWithContent {
          ...ListWithContent
        }
        ... on DatoCmsClinicCardGrid {
          ...ClinicCardGrid
        }
        ... on DatoCmsTeamMemberCardGrid {
          ...TeamMemberCardGrid
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
        ... on DatoCmsSimpleCardGrid {
          ...SimpleCardGrid
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsBlockHeader {
          ...BlockHeader
        }
        ... on DatoCmsVideo {
          ...Video
        }
        ... on DatoCmsInfographic {
          ...Infographic
        }
        ... on DatoCmsTestimonial {
          ...Testimonial
        }
      }
    }
    allSitePage {
      nodes {
        ...MenuHelpersAllPages
      }
    }
  }
`
